<template>
  <div
    v-if="list.length"
    class="list mb-m">
    <div
      v-for="item in list"
      :key="item.id"
      class="card">
      <div class="card__icon">
        <img
          :src="item.icon"
          alt="Достижение" />
      </div>
      <div class="card__data">
        <div class="card__title">{{ item.role.name }}</div>
        <div class="card__meta">{{ item.stage_title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import cupIcon from "@/assets/images/achievements/cup.svg";
import starIcon from "@/assets/images/achievements/star.svg";
export default {
  name: "StudentProfileAchievements",
  props: {
    achievements: {
      type: Array,
    },
    profileId: {
      type: Number,
    },
  },

  computed: {
    list() {
      return this.achievements
        .filter((n) => n.profile_id === this.profileId)
        .map((n) => {
          return {
            ...n,
            icon: String(n.role).toLowerCase().includes("участник")
              ? starIcon
              : cupIcon,
          };
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.card {
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  width: 280px;
  box-shadow: 0px 0px 3px #cfd8fa;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &__icon {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    margin-right: 12px;
    background-color: @bg-color;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
      height: auto;
    }
  }

  &__data {
    flex-grow: 1;
    min-width: 1px;
  }
  &__title {
    font-weight: bold;
    font-size: 16px;
  }

  &__meta {
    font-size: 14px;
  }
}
</style>
