<template>
  <div>
    <div v-if="studentProfiles.length">
      <div
        v-for="profile in studentProfiles"
        :key="profile.id"
        class="mb-xl">
        <div class="text-size-h4 text-bold color-secondary mb-s">
          Профиль&nbsp;&mdash;&nbsp;
          <router-link
            class="link"
            :to="{
              name: 'mentor-profile',
              params: {
                id: profile.id,
              },
            }">
            {{ profile.title }}
          </router-link>
        </div>
        <StudentProfileAchievements
          :achievements="achievements"
          :profile-id="profile.id" />

        <div class="mb-s">
          <div v-if="profilesScores[profile.id]">
            <ScoreInfo
              v-for="(item, idx) in profilesScores[profile.id]"
              :key="idx"
              :score="item.score"
              :track="item.track"
              :updated="item.updated_at"
              class="mb-s" />
          </div>
          <div v-else>Нет данных по набранным баллам</div>
        </div>
        <div
          v-if="
            profilesResults[profile.id] && profilesResults[profile.id].length
          "
          class="mb-m">
          <div class="text-bold">Результаты:</div>
          <div
            v-for="(result, rIdx) in profilesResults[profile.id]"
            :key="rIdx">
            <div class="mb-s">
              <span class="color-meta">{{ result.trackTitle }}:</span>&nbsp;
              <span
                v-if="result.failed"
                class="color-error"
                ><BaseIcon glyph="warn" />&nbsp;Индивидуальный этап не
                пройден</span
              >
              <span
                v-else-if="result.winner"
                class="color-success"
                ><BaseIcon glyph="done" />&nbsp;Индивидуальный этап
                пройден</span
              >
              <span v-else>Итоги не подведены</span>
            </div>
          </div>
        </div>

        <div v-if="filtredActivities[profile.id]">
          <div class="mb-m text-bold">Список заданий:</div>
          <ActivityCard
            v-for="item in filtredActivities[profile.id]"
            :key="item.id"
            class="mb-xs"
            :activity="item"
            :score="activityScores[item.id]"
            :student-id="student.id"
            :is-mentor="true"></ActivityCard>
        </div>
      </div>
    </div>
    <div v-else>
      <p>У этого участника нет выбранных профилей олимпиады</p>
    </div>
  </div>
</template>

<script>
/**
 * Данные по студенту по индивидуальному этапу
 */
import { mapState } from "vuex";
import {
  FINAL_STAGE,
  INDIVIDUAL_STAGE,
  JUNIOR_ALIAS,
  TEAM_STAGE,
} from "@/constants";
import ActivityCard from "@/components/profile/ActivityCard";
import ScoreInfo from "@/components/profile/ScoreInfo";
import StudentProfileAchievements from "@/components/mentor/StudentProfileAchievements";
export default {
  name: "StudentIndividualProfiles",
  components: { ActivityCard, ScoreInfo, StudentProfileAchievements },
  props: {
    student: {
      type: Object,
    },
    selected: {
      type: Array,
    },
    achievements: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      tracks: (state) => state.tracks,
      favoriteProfiles: (state) => state.mentor.profiles,
    }),
    studentTrack() {
      const { tracks, student } = this;
      const trackId = student?.track_id;
      if (trackId) {
        return tracks[trackId];
      }
      return undefined;
    },
    profilesScores() {
      const { student, studentTrack, tracks } = this;
      if (!student?.profile_scores || !studentTrack) return {};
      return student.profile_scores
        .filter((n) => n.stage === INDIVIDUAL_STAGE)
        .reduce((acc, val) => {
          if (!acc[val.profile_id]) {
            acc[val.profile_id] = [];
          }
          acc[val.profile_id].push({
            track: tracks[val.track_id]?.title,
            score: val.score,
            updated_at: val.updated_at,
          });
          return acc;
        }, {});
    },
    // Отфильтрованные активности
    // для 10-классников не нужно показывать активности 8-9
    // для 8-9 c предпочтительным треком, нужно показывать только его
    // для студентов - все
    filtredActivities() {
      const { studentProfiles, profiles, student, studentTrack } = this;
      const result = {};
      if (!studentProfiles?.length || !studentTrack) return result;
      const isLowTrack =
        student?.prefered_track_id &&
        student?.prefered_track_id === student.track_id;

      studentProfiles.reduce((acc, p) => {
        const profile = profiles[p.id];
        if (!profile) return acc;
        acc[p.id] = profile?.activities.filter((activity) => {
          if (!activity.is_active) return false;
          // Если юзер сказал, что не хочет решать задания
          // повышенного уровня, то не будем показывать их
          // наставнику
          if (isLowTrack) {
            return (
              activity.stage === INDIVIDUAL_STAGE &&
              activity.tracks.length === 1 &&
              activity.tracks[0].id === student.track_id
            );
          }
          // Иначе вернем только активности из трека
          return (
            activity.stage === INDIVIDUAL_STAGE &&
            activity.tracks.some((activityTrack) => {
              return activityTrack.id === studentTrack.id;
            })
          );
        });
        return acc;
      }, result);
      return result;
    },
    studentProfiles() {
      const list = this.student?.profiles;
      if (!list) return;
      const ids = [];
      return list.reduce((acc, value) => {
        if (!ids.includes(value.id)) {
          if (this.favoriteProfiles[value.id]) {
            acc.unshift(value);
          } else {
            acc.push(value);
          }
          ids.push(value.id);
        }
        return acc;
      }, []);
    },
    activityScores() {
      const { student } = this;
      if (!student?.activity_scores) return {};
      return student.activity_scores.reduce((acc, val) => {
        acc[val.activity_id] = val;
        return acc;
      }, {});
    },
    /**
     * результаты по профилю. По каким профилям и трекам
     * юзер прошел в следующий этап.
     * если профиль `failed` - значит не прошел
     * если есть стейдж (не failed и не rejected) со следующим этапом - значит прошел
     * если ничего нет - то хз
     */
    profilesResults() {
      const { selected, tracks, studentTrack } = this;
      if (!selected?.length) return {};
      const individualResults = selected.filter(
        (n) => n.stage === INDIVIDUAL_STAGE
      );
      const isJunior = studentTrack?.alias === JUNIOR_ALIAS;
      // у большой онти следующий этап - командный.
      // у джунов - Финал
      const nextStageProfiles = selected.filter((n) => {
        return n.stage === (isJunior ? FINAL_STAGE : TEAM_STAGE);
      });

      return individualResults.reduce((acc, val) => {
        if (!acc[val.profile.id]) {
          acc[val.profile.id] = [];
        }
        if (val.track_id) {
          const result = {
            failed: val.failed,
            track_id: +val.track_id,
            trackTitle: tracks[val.track_id]?.title,
          };
          // если профиль не провален,
          // то попробуем найти следующий стейдж
          // если он есть - то профиль в этом этапе пройден
          if (!result.failed) {
            result.winner = nextStageProfiles.some((n) => {
              return n.profile.id === val.profile.id && !n.rejected;
            });
          }
          // еще нужно исключить проваленные профили
          // для учеников 8-9 классов, если этот профиль провален
          // в треке, в котором ученик не хотел участвовать
          // const shouldBeHidden =
          //   result.failed &&
          //   student.prefered_track_id &&
          //   +val.track_id !== student.prefered_track_id;

          if (result.failed || result.winner) {
            acc[val.profile.id].push(result);
          }
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style></style>
