<template>
  <section class="mt-card panel radius-m">
    <div class="color-meta text-size-s">Команда</div>
    <h4 class="text-size-h4 text-bold">{{ team.title }}</h4>
    <p
      v-if="team.description"
      class="text-size-m">
      {{ team.description }}
    </p>
    <div class="mt-m">
      <div class="text-bold">Баллы команды:</div>
      <div v-if="scores">
        <ScoreInfo
          v-for="(item, idx) in scores"
          :key="idx"
          :score="item.score"
          :track="item.track"
          :updated="item.updated_at"
          class="mb-s" />
      </div>
      <div v-else>Нет данных по набранным баллам</div>
    </div>
    <div class="row team-persons card-list mt-m">
      <div
        v-for="teamUser in users"
        :key="teamUser.id"
        class="team-persons__item card-list__item col-md-6 col-xs-12">
        <div class="person-card">
          <div class="person-card__image">
            <BaseAvatar :image="teamUser.avatar" />
          </div>
          <div class="person-card__data">
            <div class="color-meta text-size-s">
              <span v-if="teamUser.id === team.owner"
                >id: {{ teamUser.id }} - Капитан команды</span
              >
              <span v-else>id: {{ teamUser.id }}</span>
            </div>
            <a
              href="#"
              title="Смотреть портфолио"
              class="text-bold link">
              {{ teamUser.first_name }} {{ teamUser.last_name }}
            </a>
            <div class="text-size-s">
              <div>{{ teamUser.email }}</div>
              <div>{{ teamUser.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ScoreInfo from "@/components/profile/ScoreInfo";

export default {
  name: "MentorTeamCard",
  components: {
    ScoreInfo,
  },
  props: {
    team: {
      type: Object,
      required: true,
    },
    scores: {
      type: Array,
    },
  },
  computed: {
    users() {
      if (Array.isArray(this.team?.persons)) {
        return this.team.persons
          .map((n) => {
            return n.person?.user;
          })
          .filter(Boolean);
      }
      return [];
    },
  },
};
</script>

<style lang="less" scoped>
.person-card {
  display: flex;
  flex-flow: row nowrap;

  &__image {
    width: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
  }
  &__data {
    flex-grow: 1;
  }
}
</style>
