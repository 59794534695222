<template>
  <div class="score-info">
    <span class="color-meta">Трек: {{ track }}</span
    >, <span class="text-bold">Баллы: {{ score }}</span>
    <div
      v-if="updatedText"
      class="color-meta">
      Обновлено {{ updatedText }}
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";

export default {
  name: "ScoreInfo",
  props: {
    score: {
      type: Number,
      default: 0,
    },
    track: {
      type: String,
    },
    updated: {
      type: [String, Number],
    },
  },
  computed: {
    updatedText() {
      if (!this.updated) return undefined;
      return dayjs(this.syncTimeStamp).to(this.updated);
    },
  },
};
</script>

<style></style>
