<template>
  <div v-if="pending"><BaseSpinner brand /></div>
  <div v-else>
    <div
      v-if="student"
      class="u-page">
      <div class="panel radius-s mb-xxl">
        <div class="u-page__profile">
          <div class="u-page__image">
            <BaseAvatar
              :gender="student.sex"
              :image="student.avatar"
              class="u-page__avatar" />
          </div>
          <div class="text-size-h2 text-bold mb-xs">
            {{ student.first_name }} {{ student.last_name }}
          </div>
          <div class="mb-m color-meta">
            <span v-if="gradeText"> {{ gradeText }}&emsp;</span
            >{{ student.education_title }}&emsp;Трек: {{ trackTitle }}
          </div>

          <div class="u-page__connect">
            <a
              v-if="allowSendNotify"
              href="#"
              class="u-page__connect-link"
              @click.prevent="handleNotifyStudent"
              ><BaseIcon glyph="connect" /> Предложить связаться</a
            >
            <span
              v-else
              class="color-success"
              ><BaseIcon glyph="done" />&nbsp;Сообщение отправлено</span
            >
          </div>

          <div>
            <AppTag
              v-for="item in student.profiles"
              :key="item.id"
              tag="router-link"
              :to="{
                name: 'mentor-profile',
                params: {
                  id: item.id,
                },
              }"
              class="u-page__tag text-size-s">
              {{ item.title }}
            </AppTag>
          </div>
        </div>
      </div>

      <div class="text-size-h2 text-bold mb-s">Прогресс ученика</div>

      <div
        v-if="studentStages.length > 1"
        class="u-page__tabs mb-m">
        <BaseButton
          v-for="(item, idx) in studentStages"
          :key="idx"
          class="u-page__tab"
          :theme="currentStage === item.value ? 'primary' : 'primary-border'"
          @click.prevent="currentStage = item.value"
          >{{ item.title }}</BaseButton
        >
      </div>
      <component
        :is="currentComponent"
        class="mt-l"
        :student="student"
        :teams="studentTeams"
        :achievements="stageAchievements"
        :selected="studentProfiles" />
    </div>
  </div>
</template>

<script>
/**
 * @page MentorUser
 * Внутренности ученика
 */
import AppTag from "@/components/AppTag";
import { request, talentRequest } from "@/services/api";
import {
  INDIVIDUAL_STAGE,
  JUNIOR_ALIAS,
  LOW_SCHOOL_ALIAS,
  SCHOOL_ALIAS,
  STUDENT_ALIAS,
  TEAM_STAGE,
} from "@/constants";
const SEND_NOTIFY_TIMEOUT = 1000 * 60 * 60 * 1; // 1 час
import StudentIndividualProfiles from "@/components/mentor/StudentIndividualProfiles";
import StudentTeamProfiles from "@/components/mentor/StudentTeamProfiles";
export default {
  name: "MentorUser",
  components: {
    AppTag,
    StudentIndividualProfiles,
    StudentTeamProfiles,
  },
  metaInfo() {
    return {
      title: "Информация об ученике",
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      student: null,
      pending: false,
      sendPending: false,
      teamStage: TEAM_STAGE,
      individualStage: INDIVIDUAL_STAGE,
      currentStage: null,
      studentProfiles: [],
      studentTeams: {},
      achievements: [],
    };
  },
  computed: {
    currentComponent() {
      const { currentStage } = this;
      if (currentStage === this.individualStage) {
        return "StudentIndividualProfiles";
      }
      if (currentStage === this.teamStage) {
        return "StudentTeamProfiles";
      }
      return undefined;
    },
    tracks() {
      return this.$store.state.tracks;
    },
    studentTrack() {
      const { tracks, student } = this;
      const trackId = student?.prefered_track_id || student?.track_id;
      if (trackId) {
        return tracks[trackId];
      }
      return undefined;
    },
    gradeText() {
      const { studentTrack, student } = this;
      return studentTrack?.alias !== STUDENT_ALIAS
        ? `${student.grade} класс`
        : "";
    },
    trackTitle() {
      const { studentTrack } = this;
      return studentTrack?.title;
    },
    /**
     * Проверяем, отправляли ли мы нотис уже этому
     * юзеруб за последние n часов (чтобы не спамили)
     */
    allowSendNotify() {
      const { id } = this.$route.params;
      const noticeTime = this.$store.state.mentor.notifiedStudents[id];
      const now = Date.now();
      if (!noticeTime) return true;
      return now - noticeTime > SEND_NOTIFY_TIMEOUT;
    },
    studentStages() {
      const { studentTrack } = this;
      if ([LOW_SCHOOL_ALIAS, SCHOOL_ALIAS].includes(studentTrack?.alias)) {
        return [
          {
            title: "Этап 1",
            value: INDIVIDUAL_STAGE,
          },
          {
            title: "Этап 2",
            value: TEAM_STAGE,
          },
        ];
      }
      if (studentTrack?.alias === JUNIOR_ALIAS) {
        return [
          {
            title: "Этап 1",
            value: INDIVIDUAL_STAGE,
          },
        ];
      }
      if (studentTrack?.alias === STUDENT_ALIAS) {
        return [
          {
            title: "Этап 1",
            value: TEAM_STAGE,
          },
        ];
      }
      return [];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    stages() {
      const list = this.$store.getters["profile/allProfiles"];
      const stages = list.reduce((acc, profile) => {
        if (profile.steps) {
          profile.steps.forEach((step) => {
            if (step.talent_event_id) {
              acc[step.talent_event_id] = {
                ...step,
                profile_id: profile.id,
                profile_title: profile.title,
                stage: step.stage,
                stage_title:
                  step.stage === TEAM_STAGE
                    ? "Командный этап"
                    : step.stage === INDIVIDUAL_STAGE
                    ? "Индивидуальный этап"
                    : "Финал",
              };
            }
          });
        }
        return acc;
      }, {});
      return stages;
    },
    // eslint-disable-next-line vue/no-unused-properties
    stageAchievements() {
      const { stages, achievements } = this;
      return achievements.reduce((acc, achievement) => {
        if (stages[achievement.event]) {
          acc.push({
            role: achievement.role,
            ...stages[achievement.event],
          });
        }
        return acc;
      }, []);
    },
  },
  watch: {
    "$route.params.id": {
      handler(val) {
        if (val) {
          this.init();
        }
      },
    },
  },
  created() {
    this.$store.dispatch("getAchievementRoles");
    this.init();
  },
  methods: {
    /**
     * Послать уведомление студенту с просьбой связаться
     */
    async handleNotifyStudent() {
      if (this.sendPending) return;
      this.sendPending = true;
      try {
        await request({
          url: `/mentor/notify/${this.$route.params.id}`,
          method: "POST",
        });
        this.$store.commit(
          "mentor/SET_NOTIFIED_STUDENT",
          this.$route.params.id
        );
      } catch (error) {
        this.showErrorModal({
          message: error.message,
          content: "Не удалось отправить сообщение",
        });
      }
      this.sendPending = false;
    },

    async getStudentAchievements(organization_id, student_talent_id) {
      const { user } = this;
      if (!organization_id || !student_talent_id || !user?.talent_id) {
        this.achievements = [];
        return;
      }
      try {
        const { data } = await request({
          method: "GET",
          url: `/mentor/students/${this.$route.params.id}/achievements/${this.$store.state.season?.talent_event}`,
        });
        this.achievements = data || [];
      } catch (error) {
        this.achievements = [];
      }
    },

    async init() {
      this.pending = true;
      try {
        const { data } = await request({
          method: "GET",
          url: `/mentor/students/${this.$route.params.id}`,
        });
        this.student = data;
        // Получаем данные по состоянию профилей участника
        const { data: studentProfiles } = await request({
          url: `/selected_profiles`,
          method: "GET",
          params: {
            talent_id: this.student?.user?.talent_id,
          },
        });
        this.studentProfiles = studentProfiles?.items;
        // выставим стейдж по умолчанию
        this.currentStage =
          this.studentStages.length && this.studentStages[0]?.value;

        // Запросим команды участника
        // если оные есть
        if (studentProfiles?.items?.length) {
          const profilesWithTeams = studentProfiles.items.filter(
            (p) => p.stage === TEAM_STAGE && p.team_id
          );
          try {
            if (profilesWithTeams.length) {
              // Данные о команде берем из таланта
              const teamRequests = await Promise.all(
                profilesWithTeams.map((n) => {
                  return talentRequest({
                    method: "GET",
                    url: `/api/teams/${n.team_id}/`,
                  });
                })
              );
              const teams = teamRequests.reduce((acc, req, idx) => {
                if (req.data?.title) {
                  // idx - потому что не возвращается id
                  acc[profilesWithTeams[idx]?.team_id] = req.data;
                }
                return acc;
              }, {});
              this.studentTeams = teams;
            }
          } catch (error) {
            this.showErrorModal({
              content: "Не удалось получить данные о команде",
              message: error.message,
            });
          }
        }

        await this.getStudentAchievements(
          data.organization_id,
          data.user?.talent_id
        );
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить данные о студенте",
          message: error.message,
        });
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.u-page {
  margin-top: 30px;

  &__profile {
    position: relative;
  }

  &__connect {
    position: absolute;
    top: 30px;
    right: 0;

    &-link {
      color: @link-color;
      text-decoration: none;

      ::v-deep .base-icon {
        font-size: 1.4em;
        margin-right: 0.2em;
      }
    }
  }

  &__image {
    width: 60px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 15px;
    margin-top: -40px;
    margin-bottom: 20px;
    position: relative;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 10px 10px #cfd8fa;
  }
  &__tag {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  &__tab {
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
