var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.studentProfiles.length)?_c('div',_vm._l((_vm.studentProfiles),function(profile){return _c('div',{key:profile.id,staticClass:"mb-xl"},[_c('div',{staticClass:"text-size-h4 text-bold color-secondary mb-s"},[_vm._v(" Профиль —  "),_c('router-link',{staticClass:"link",attrs:{"to":{
            name: 'mentor-profile',
            params: {
              id: profile.id,
            },
          }}},[_vm._v(" "+_vm._s(profile.title)+" ")])],1),_c('StudentProfileAchievements',{attrs:{"achievements":_vm.achievements,"profile-id":profile.id}}),_c('div',{staticClass:"mb-s"},[(_vm.profilesScores[profile.id])?_c('div',_vm._l((_vm.profilesScores[profile.id]),function(item,idx){return _c('ScoreInfo',{key:idx,staticClass:"mb-s",attrs:{"score":item.score,"track":item.track,"updated":item.updated_at}})}),1):_c('div',[_vm._v("Нет данных по набранным баллам")])]),(
          _vm.profilesResults[profile.id] && _vm.profilesResults[profile.id].length
        )?_c('div',{staticClass:"mb-m"},[_c('div',{staticClass:"text-bold"},[_vm._v("Результаты:")]),_vm._l((_vm.profilesResults[profile.id]),function(result,rIdx){return _c('div',{key:rIdx},[_c('div',{staticClass:"mb-s"},[_c('span',{staticClass:"color-meta"},[_vm._v(_vm._s(result.trackTitle)+":")]),_vm._v("  "),(result.failed)?_c('span',{staticClass:"color-error"},[_c('BaseIcon',{attrs:{"glyph":"warn"}}),_vm._v(" Индивидуальный этап не пройден")],1):(result.winner)?_c('span',{staticClass:"color-success"},[_c('BaseIcon',{attrs:{"glyph":"done"}}),_vm._v(" Индивидуальный этап пройден")],1):_c('span',[_vm._v("Итоги не подведены")])])])})],2):_vm._e(),(_vm.filtredActivities[profile.id])?_c('div',[_c('div',{staticClass:"mb-m text-bold"},[_vm._v("Список заданий:")]),_vm._l((_vm.filtredActivities[profile.id]),function(item){return _c('ActivityCard',{key:item.id,staticClass:"mb-xs",attrs:{"activity":item,"score":_vm.activityScores[item.id],"student-id":_vm.student.id,"is-mentor":true}})})],2):_vm._e()],1)}),0):_c('div',[_c('p',[_vm._v("У этого участника нет выбранных профилей олимпиады")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }