<template>
  <div>
    <div v-if="studentProfiles.length">
      <div
        v-for="profile in studentProfiles"
        :key="profile.profile.id"
        class="mb-xl">
        <div class="text-size-h4 text-bold color-secondary mb-s">
          Профиль&nbsp;&mdash;&nbsp;
          <router-link
            class="link"
            :to="{
              name: 'mentor-profile',
              params: {
                id: profile.profile.id,
              },
            }">
            {{ profile.profile.title }}
          </router-link>
        </div>

        <div class="mb-s">
          <div
            v-if="!profile.team_id"
            class="mb-s">
            <div v-if="profilesScores[profile.profile.id]">
              <ScoreInfo
                v-for="(item, idx) in profilesScores[profile.profile.id]"
                :key="idx"
                :score="item.score"
                :track="item.track"
                :updated="item.updated_at"
                class="mb-s" />
            </div>
            <div v-else>Нет данных по набранным баллам</div>

            <p class="mt-m">
              Команда:
              <span class="color-error">
                <BaseIcon glyph="warn" />&nbsp;Участник еще не создал или не
                присоединился к команде</span
              >
            </p>
          </div>
          <MentorTeamCard
            v-else-if="teams[profile.team_id]"
            :team="teams[profile.team_id]"
            :scores="profilesScores[profile.profile.id]" />
          <!-- если по каким то причинам не получили команду -->
          <div v-else>
            <div class="form-error">
              Не удалось получить данные о команде {{ profile.team_id }}
            </div>
          </div>
        </div>

        <div
          v-if="activities[profile.profile.id]"
          class="mt-m">
          <ActivityCard
            v-for="item in activities[profile.profile.id]"
            :key="item.id"
            class="mb-xs"
            :activity="item"
            :score="activityScores[item.id]"
            :student-id="student.id"
            :is-mentor="true"></ActivityCard>
        </div>
      </div>
    </div>

    <div v-else>
      {{ student.first_name }} {{ student.last_name }} не участвует в командном
      этапе.
    </div>
  </div>
</template>

<script>
import { TEAM_STAGE } from "@/constants";
import { mapState } from "vuex";
import ActivityCard from "@/components/profile/ActivityCard";
import ScoreInfo from "@/components/profile/ScoreInfo";
import MentorTeamCard from "@/components/mentor/MentoTeamCard.vue";
export default {
  name: "StudentTeamProfiles",
  components: { ActivityCard, ScoreInfo, MentorTeamCard },
  props: {
    student: {
      type: Object,
    },
    /**
     * Выбранные профили студента
     */
    selected: {
      type: Array,
    },
    teams: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      tracks: (state) => state.tracks,
      favoriteProfiles: (state) => state.mentor.profiles,
    }),
    profilesScores() {
      const { student, tracks } = this;
      if (!student?.profile_scores) return {};
      return student.profile_scores
        .filter((n) => n.stage === TEAM_STAGE)
        .reduce((acc, val) => {
          if (!acc[val.profile_id]) {
            acc[val.profile_id] = [];
          }
          acc[val.profile_id].push({
            track: tracks[val.track_id]?.title,
            score: val.score,
            updated_at: val.updated_at,
          });
          return acc;
        }, {});
    },
    studentProfiles() {
      const list = this.selected;
      if (!list) return;
      const ids = [];
      return list.reduce((acc, value) => {
        if (value.stage !== TEAM_STAGE) return acc;
        if (!ids.includes(value.id)) {
          if (this.favoriteProfiles[value.id]) {
            acc.unshift(value);
          } else {
            acc.push(value);
          }
          ids.push(value.id);
        }
        return acc;
      }, []);
    },

    // Отфильтрованные активности
    activities() {
      const { studentProfiles, profiles } = this;
      const result = {};

      if (!studentProfiles?.length) return result;

      studentProfiles.reduce((acc, sProf) => {
        const profile = profiles[sProf.profile.id];
        if (!profile) return acc;
        acc[sProf.profile.id] = profile.activities.filter((activity) => {
          if (!activity.is_active) return false;
          return (
            activity.stage === TEAM_STAGE &&
            activity.tracks.some((activityTrack) => {
              return activityTrack.id === +sProf.track_id;
            })
          );
        });
        return acc;
      }, result);
      return result;
    },
    activityScores() {
      const { student } = this;
      if (!student?.activity_scores) return {};
      return student.activity_scores.reduce((acc, val) => {
        acc[val.activity_id] = val;
        return acc;
      }, {});
    },
  },
};
</script>

<style></style>
