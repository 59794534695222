<template>
  <Component
    :is="tag"
    :to="to"
    class="a-tag"
    :class="[`a-tag--theme-${theme}`, { 'a-tag--hoverable': isHoverable }]"
    ><slot></slot
  ></Component>
</template>

<script>
const CLICKABLE_TAGS = ["RouterLink", "router-link", "a", "button"];
export default {
  name: "AppTag",
  props: {
    to: [String, Object],
    tag: {
      type: String,
      default: "div",
    },
    theme: {
      type: String,
      default: "default", // border, inverse, success, light-gray
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isHoverable() {
      return CLICKABLE_TAGS.includes(this.tag) || this.clickable;
    },
  },
};
</script>

<style lang="less">
.a-tag {
  padding: 0.2em 0.95em;
  display: inline-flex;
  align-items: center;
  line-height: 19/15;
  background-color: @bg-light;
  border-radius: @radius-xl;
  text-decoration: none;
  color: @base-color;
  border: 0;
  transition: background-color 0.3s, color 0.3s;

  &--theme-border {
    background-color: transparent;
    border: 1px solid;
  }

  &--theme-primary {
    background-color: @primary-color;
    color: #fff;
  }

  &--theme-inverse {
    background-color: #fff;
  }
  &--hoverable {
    cursor: pointer;
    &:hover {
      color: @link-blue;
    }
  }
  &--theme-success {
    background-color: @success-color;
    color: #fff;
  }

  &--theme-success&--hoverable:hover {
    background-color: darken(@success-color, 5%);
    color: #fff;
  }

  &--theme-light-gray {
    background-color: #f3f3f3;
    color: #626262;
  }
  &--theme-light-gray&--hoverable:hover {
    background-color: #959595;
    color: #fff;
  }

  &--theme-accent-blue {
    background-color: @accent-blue;
    color: #fff;
  }
  &--theme-accent-blue&--hoverable:hover {
    background-color: darken(@accent-blue, 10%);
    color: #fff;
  }
}
</style>
